import { List } from "@mui/material";
import { UsersListItem } from "./UsersListItem";
import { IUser } from "core/types";
import { NotSearchResults } from "components/ui/NotSearchResults";
import { ErrorPage } from "pages/ErrorPage";
import { UsersSkeleton } from "../../../components/ui/UsersSkeleton";
import { FC } from "react";
import { NAVIGATION_HEIGHT } from "core/configs/constants";

interface IUsersListProps {
  users: IUser[] | undefined;
  isLoading?: boolean;
  isMutation?: boolean;
  error?: any;
}

export const UsersList: FC<IUsersListProps> = ({
  users,
  isLoading,
  isMutation,
  error,
}) => {
  if (isLoading || isMutation) {
    return <UsersSkeleton />;
  }

  if (!users || error) {
    return (
      <ErrorPage
        error={{
          message: error?.message,
          status: error?.response?.status,
        }}
      />
    );
  }

  if (users.length === 0) {
    return <NotSearchResults />;
  }

  return (
    <List
      sx={{
        width: "100%",
        pb: `${NAVIGATION_HEIGHT}px`,
      }}>
      {users.map((user: IUser) => (
        <UsersListItem key={user.id} user={user} />
      ))}
    </List>
  );
};
