import { List } from "@mui/material";
import { IDepartment, IDepartmentsProps } from "core/types";
import { DepartmentsListItem } from "./DepartmentsListItem";
import { FC } from "react";
import { NAVIGATION_HEIGHT } from "core/configs/constants";

export const DepartmentsList: FC<IDepartmentsProps> = ({ departments }) => {
  return (
    <List
      sx={{
        width: "100%",
        pb: `${NAVIGATION_HEIGHT}px`,
      }}>
      {departments.map((department: IDepartment) => (
        <DepartmentsListItem key={department.id} department={department} />
      ))}
    </List>
  );
};
