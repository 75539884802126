import { FC, useContext, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CancelRounded from "@mui/icons-material/CancelRounded";
import InputAdornment from "@mui/material/InputAdornment";
import { useTgTheme } from "core/hooks/useTgTheme";
import { KeyboardHideRounded } from "@mui/icons-material";
import { SearchContext } from "core/context/SearchContext";

interface IUsersSearchProps {
  isLoading: boolean;
}

export const UsersSearch: FC<IUsersSearchProps> = ({ isLoading }) => {
  const { search, setSearch } = useContext(SearchContext);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { section_bg_color, text_color, button_color } = useTgTheme();

  const blurInput = () => {
    setIsFocused(false);
    inputRef.current?.blur();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const removeSearch = () => {
    setSearch("");
  };

  return (
    <Box
      sx={{
        padding: "16px 8px 8px 8px",
        borderBottom: `1px solid ${section_bg_color}`,
        display: "flex",
        alignItems: "center",
      }}>
      <TextField
        disabled={isLoading}
        label="Поиск"
        variant="outlined"
        inputRef={inputRef}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={handleFocus}
        fullWidth
        size="small"
        value={search}
        sx={{
          color: text_color,
          "& .MuiInputLabel-root": {
            color: text_color,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: button_color,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {search && (
                <IconButton onClick={removeSearch} edge="end">
                  <CancelRounded sx={{ color: button_color }} />
                </IconButton>
              )}
            </InputAdornment>
          ),
          style: {
            color: text_color,
            backgroundColor: section_bg_color,
          },
        }}
      />
      {isFocused && (
        <IconButton
          sx={{ marginLeft: "8px", color: button_color }}
          onClick={blurInput}>
          <KeyboardHideRounded />
        </IconButton>
      )}
    </Box>
  );
};
