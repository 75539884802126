import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { IUserProps } from "core/types";
import { UserPhoto } from "../../../components/UserPhoto";
import { useTelegram } from "core/hooks/useTelegram";
import { SendRounded } from "@mui/icons-material";
import { useTgTheme } from "core/hooks/useTgTheme";
import { getNames } from "core/utils/getNames";
import { ListItemTextComponent } from "components/ui/ListItemTextComponent";
import { FC } from "react";
import { NAVIGATION_HEIGHT } from "core/configs/constants";

export const UserCart: FC<IUserProps> = ({ user }) => {
  const { tg } = useTelegram();
  const { subtitle_text_color, section_bg_color, button_color } = useTgTheme();

  const openTelegramChat = () => {
    if (user.tg_username) {
      tg.openTelegramLink(`https://t.me/${user.tg_username}`);
    }
  };

  const departmentsNames = getNames(user, "departments");
  const subDepartmentsNames = getNames(user, "sub_departments");
  const projectNames = getNames(user, "projects");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        pt: "40px",
        pb: `${NAVIGATION_HEIGHT}px`,
      }}>
      <UserPhoto size={"large"} user={user} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography sx={{ fontSize: "20px" }} noWrap>
          {`${user.first_name} ${user.last_name}`}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: subtitle_text_color,
          }}
          noWrap>
          {user.email}
        </Typography>
      </Box>
      {user.tg_username ? (
        <Button
          variant="outlined"
          endIcon={<SendRounded />}
          onClick={openTelegramChat}>
          <Typography sx={{ textTransform: "lowercase" }}>
            {`@${user.tg_username}`}
          </Typography>
        </Button>
      ) : (
        <Typography
          sx={{
            color: button_color,
          }}>{`ID: ${user.telegram_id}`}</Typography>
      )}
      <List component="div" sx={{ width: "100%" }}>
        <ListItem>
          <ListItemTextComponent
            primary="Должность:"
            secondary={user.position.name}
          />
        </ListItem>
        <Divider sx={{ bgcolor: section_bg_color }} component="li" />
        <ListItem>
          <ListItemTextComponent
            primary="Департаменты:"
            secondary={departmentsNames}
          />
        </ListItem>
        <Divider sx={{ bgcolor: section_bg_color }} component="li" />
        <ListItem>
          <ListItemTextComponent
            primary="Субдепартаменты:"
            secondary={subDepartmentsNames}
          />
        </ListItem>
        <Divider sx={{ bgcolor: section_bg_color }} component="li" />
        <ListItem>
          <ListItemTextComponent primary="Проекты:" secondary={projectNames} />
        </ListItem>
      </List>
    </Box>
  );
};
