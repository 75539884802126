import { UsersList } from "./components/UsersList";
import { Box } from "@mui/material";
import { getAllUsers } from "../../core/services/users.service";
import useSWRImmutable from "swr/immutable";
import { IUsersResponse } from "core/types";
import { useState } from "react";
import { USERS_HEADER_HEIGHT } from "core/configs/constants";
import { UsersHeader } from "./UsersHeader";

export const UsersPage = () => {
  const [isMutation, setIsMutation] = useState<boolean>(false);

  const { data, error, isLoading } = useSWRImmutable<IUsersResponse>(
    "users",
    getAllUsers
  );

  const users = data?.result;

  return (
    <>
      <UsersHeader setIsMutation={setIsMutation} isLoading={isLoading} />
      <Box
        sx={{
          boxSizing: "border-box",
          height: "100%",
          pt: `${USERS_HEADER_HEIGHT}px`,
        }}>
        <UsersList
          isMutation={isMutation}
          users={users}
          isLoading={isLoading}
          error={error}
        />
      </Box>
    </>
  );
};
