import { PAGE_SIZE } from "core/configs/constants";
import { endpoints } from "core/configs/endpoints";
import { fetchData } from "core/utils/fetchData";

export const getAllUsers = () =>
  fetchData(`${endpoints.users}`, {
    pageSize: PAGE_SIZE,
    order_by: "last_name",
  });

export const getUserById = (id: number) =>
  fetchData(`${endpoints.users}/${id}`);

export const getUsersBySearch = (
  q: string,
  department_ids: number[],
  sub_department_ids: number[],
  project_ids: number[]
) =>
  fetchData(`${endpoints.users}`, {
    q,
    department_ids,
    sub_department_ids,
    project_ids,
    pageSize: PAGE_SIZE,
    order_by: "last_name",
  });
