const stringToColor = (name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return (
    "#" +
    [0, 8, 16]
      .map((shift) => ((hash >> shift) & 0xff).toString(16).padStart(2, "0"))
      .join("")
  );
};

export const stringAvatar = (firstName: string, size: string) => {
  let avatarSize = "56px";
  if (size === "large") {
    avatarSize = "180px";
  }

  const initials = firstName[0];
  return {
    sx: {
      bgcolor: stringToColor(firstName),
      width: avatarSize,
      height: avatarSize,
    },
    children: initials,
  };
};
