import { FC } from "react";
import { routes } from "../../../core/configs/routes";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import { IDepartmentProps } from "core/types";
import { Link } from "react-router-dom";
import { useTgTheme } from "core/hooks/useTgTheme";
import { ListItemTextComponent } from "components/ui/ListItemTextComponent";

export const DepartmentsListItem: FC<IDepartmentProps> = ({ department }) => {
  const { section_bg_color } = useTgTheme();
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to={`${routes.DEPARTMENTS}/${department.id}`}>
          <ListItemTextComponent primary={department.name} />
        </ListItemButton>
      </ListItem>
      <Divider
        sx={{ bgcolor: section_bg_color }}
        variant="inset"
        component="li"
      />
    </>
  );
};
