import { Box, Skeleton } from "@mui/material";

export const UsersSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        py: "8px",
      }}>
      {Array.from({ length: 5 }).map((_, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            p: "8px 16px",
          }}>
          <Skeleton
            variant="circular"
            height={56}
            width={56}
            sx={{
              marginRight: "20px",
            }}
          />
          <Box>
            <Skeleton
              variant="text"
              height={24}
              width={index % 2 === 0 ? 140 : 100}
            />
            <Skeleton
              variant="text"
              height={20}
              width={index % 2 === 0 ? 60 : 80}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
