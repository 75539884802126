import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTgTheme } from "core/hooks/useTgTheme";

export const Spinner = () => {
  const { button_color } = useTgTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}>
      <CircularProgress
        sx={{
          color: button_color,
        }}
      />
    </Box>
  );
};
