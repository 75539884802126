import { endpoints } from "core/configs/endpoints";
import { fetchData } from "core/utils/fetchData";

export const getAllDepartments = () => fetchData(`${endpoints.departments}`);

export const getDepartmentById = (id: number) =>
  fetchData(`${endpoints.departments}/${id}`);

export const getDepartmentUsers = (id: number) =>
  fetchData(`${endpoints.departments}/${id}/${endpoints.users}`);
