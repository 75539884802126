import { IError } from "core/types";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { HomeRounded, ReplayRounded } from "@mui/icons-material";
import { useTgTheme } from "core/hooks/useTgTheme";
import { routes } from "core/configs/routes";

interface IErrorPageProps {
  error: IError;
}

export const ErrorPage = ({ error }: IErrorPageProps) => {
  const navigate = useNavigate();
  const { destructive_text_color, subtitle_text_color } = useTgTheme();

  const reloadPage = () => {
    navigate(0);
  };

  const returnToRootPage = () => {
    navigate(routes.ROOT);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        height: "300px",
      }}>
      <Typography
        sx={{
          color: destructive_text_color,
        }}>
        {error.status && error.status}
      </Typography>
      <Typography
        sx={{
          color: subtitle_text_color,
        }}>
        {`Ошибка :( ${error.message && error.message}`}
      </Typography>
      <Button
        onClick={reloadPage}
        variant="outlined"
        startIcon={<ReplayRounded />}>
        перезагрузить
      </Button>
      <Button
        onClick={returnToRootPage}
        variant="outlined"
        startIcon={<HomeRounded />}>
        на главную
      </Button>
    </Box>
  );
};
