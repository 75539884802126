import { SearchContext } from "core/context/SearchContext";
import { useState } from "react";
import { Outlet } from "react-router";

export const UsersLayout = () => {
  const [search, setSearch] = useState<string>("");

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      <Outlet />
    </SearchContext.Provider>
  );
};
