import {
  Box,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@mui/material";
import { UsersList } from "pages/Users/components/UsersList";
import { NAVIGATION_HEIGHT } from "core/configs/constants";
import { getNames } from "core/utils/getNames";
import { ListItemTextComponent } from "components/ui/ListItemTextComponent";
import { FC } from "react";
import { IDepartment, IUser } from "core/types";
import { useTgTheme } from "core/hooks/useTgTheme";

interface IDepartmentCartProps {
  department: IDepartment;
  users: IUser[] | undefined;
  usersLoading: boolean;
  usersError: any;
}

export const DepartmentCart: FC<IDepartmentCartProps> = ({
  department,
  users,
  usersLoading,
  usersError,
}) => {
  const { secondary_bg_color, section_bg_color, text_color } = useTgTheme();

  const subDepartmentsNames = getNames(department, "sub_departments");
  const projectNames = getNames(department, "projects");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        pt: "40px",
        pb: `${NAVIGATION_HEIGHT}px`,
      }}>
      <Typography sx={{ fontSize: "28px" }}>{department.name}</Typography>
      <Box
        sx={{
          width: "100%",
        }}>
        <List component="div" sx={{ width: "100%", pb: "0" }}>
          <ListItem>
            <ListItemTextComponent
              primary="Субдепартаменты:"
              secondary={subDepartmentsNames}
            />
          </ListItem>
          <Divider sx={{ bgcolor: section_bg_color }} component="li" />
          <ListItem>
            <ListItemTextComponent
              primary="Проекты:"
              secondary={projectNames}
            />
          </ListItem>
          <Divider sx={{ bgcolor: section_bg_color }} component="li" />
        </List>
        <List
          sx={{
            width: "100%",
          }}
          subheader={
            <ListSubheader
              sx={{
                fontSize: "16px",
                color: text_color,
                bgcolor: secondary_bg_color,
              }}
              component="div">
              Сотрудники:
            </ListSubheader>
          }>
          <UsersList
            users={users}
            isLoading={usersLoading}
            error={usersError}
          />
        </List>
      </Box>
    </Box>
  );
};
