import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CorporateFareRounded from "@mui/icons-material/CorporateFareRounded";
import GroupRounded from "@mui/icons-material/GroupRounded";
import HomeRounded from "@mui/icons-material/HomeRounded";
import { Paper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { routes } from "core/configs/routes";
import { useEffect, useState } from "react";
import { useTgTheme } from "core/hooks/useTgTheme";

export const Navigation = () => {
  const { bg_color, text_color } = useTgTheme();
  const location = useLocation();

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (location.pathname === routes.ROOT) {
      setValue(0);
    } else if (location.pathname.startsWith(routes.USERS)) {
      setValue(1);
    } else if (location.pathname.startsWith(routes.DEPARTMENTS)) {
      setValue(2);
    } else {
      setValue(-1);
    }
  }, [location.pathname]);

  const tabs = [
    {
      id: 1,
      route: routes.ROOT,
      label: "Главная",
      IconComponent: HomeRounded,
    },
    {
      id: 2,
      route: routes.USERS,
      label: "Сотрудники",
      IconComponent: GroupRounded,
    },
    {
      id: 3,
      route: routes.DEPARTMENTS,
      label: "Департаменты",
      IconComponent: CorporateFareRounded,
    },
  ];

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}>
      <BottomNavigation
        component={"nav"}
        showLabels
        sx={{ backgroundColor: bg_color }}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}>
        {tabs.map((tab) => (
          <BottomNavigationAction
            key={tab.id}
            component={Link}
            to={tab.route}
            sx={{
              color: text_color,
            }}
            label={tab.label}
            icon={<tab.IconComponent />}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};
