import { ListItemText } from "@mui/material";
import { useTgTheme } from "core/hooks/useTgTheme";
import { FC } from "react";

interface IListItemTextComponentProps {
  primary: string;
  secondary?: string;
}

export const ListItemTextComponent: FC<IListItemTextComponentProps> = ({
  primary,
  secondary,
}) => {
  const { subtitle_text_color } = useTgTheme();

  return (
    <ListItemText
      primary={primary}
      secondary={secondary}
      sx={{
        "& .MuiListItemText-secondary": {
          color: subtitle_text_color,
        },
      }}
    />
  );
};
