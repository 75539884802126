import { FC, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, Paper } from "@mui/material";

import { useTgTheme } from "core/hooks/useTgTheme";
import { IDepartment, IProject, ISubDepartment } from "core/types";
import useSWRImmutable from "swr/immutable";
import { getAllDepartments } from "core/services/departmaents.service";
import { mutate } from "swr";
import {
  getAllUsers,
  getUsersBySearch,
} from "../../core/services/users.service";
import { useDebounce } from "core/hooks/useDebounce";
import { SearchContext } from "core/context/SearchContext";
import { getAllSubDepartments } from "core/services/sub_departmaents.service";
import { getAllProjects } from "core/services/projects.service";
import { FormControlComponent } from "../../components/ui/FormControlComponent";
import { UsersSearch } from "./components/UserSearch";
import { handleSelectChange } from "core/utils/handleSelectChange";
import { FilterAltOffRounded } from "@mui/icons-material";

interface IUsersSearchProps {
  isLoading: boolean;
  setIsMutation: (value: boolean) => void;
}

export const UsersHeader: FC<IUsersSearchProps> = ({
  isLoading,
  setIsMutation,
}) => {
  const { data: departments } = useSWRImmutable<IDepartment[]>(
    "departments",
    getAllDepartments
  );
  const { data: sub_departments } = useSWRImmutable<ISubDepartment[]>(
    "sub_departments",
    getAllSubDepartments
  );
  const { data: projects } = useSWRImmutable<IProject[]>(
    "projects",
    getAllProjects
  );
  const { search } = useContext(SearchContext);
  const [selectedDepartment, setSelectedDepartment] = useState<number[]>([]);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState<number[]>(
    []
  );
  const [selectedProject, setSelectedProject] = useState<number[]>([]);
  const debouncedSearch = useDebounce(search, 300);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const { secondary_bg_color, section_bg_color, button_color } = useTgTheme();

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    const mutateUsers = async () => {
      // setIsMutation(true);
      const searchParams =
        debouncedSearch ||
        selectedDepartment.length > 0 ||
        selectedSubDepartment.length > 0 ||
        selectedProject.length > 0;
      let usersBySearch = searchParams
        ? await getUsersBySearch(
            debouncedSearch,
            selectedDepartment,
            selectedSubDepartment,
            selectedProject
          )
        : await getAllUsers();
      mutate("users", usersBySearch, false);
      // setIsMutation(false);
    };
    mutateUsers();
  }, [
    debouncedSearch,
    selectedDepartment,
    selectedSubDepartment,
    selectedProject,
  ]);

  const clearFilters = () => {
    setSelectedDepartment([]);
    setSelectedSubDepartment([]);
    setSelectedProject([]);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        backgroundColor: secondary_bg_color,
        borderBottom: `1px solid ${section_bg_color}`,
      }}>
      <UsersSearch isLoading={isLoading} />
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "8px",
        }}>
        <Box
          sx={{
            display: "flex",
          }}>
          <FormControlComponent
            isLoading={isLoading}
            label="Департаменты"
            value={selectedDepartment}
            handleChange={(e) => handleSelectChange(e, setSelectedDepartment)}
            options={departments}
          />
          {(selectedDepartment.length > 0 ||
            selectedSubDepartment.length > 0 ||
            selectedProject.length > 0) && (
            <IconButton
              sx={{ marginLeft: "8px", color: button_color }}
              onClick={clearFilters}>
              <FilterAltOffRounded />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "4px",
          }}>
          <FormControlComponent
            isLoading={isLoading}
            label="Субдепартаменты"
            value={selectedSubDepartment}
            handleChange={(e) =>
              handleSelectChange(e, setSelectedSubDepartment)
            }
            options={sub_departments}
          />
          <FormControlComponent
            isLoading={isLoading}
            label="Проекты"
            value={selectedProject}
            handleChange={(e) => handleSelectChange(e, setSelectedProject)}
            options={projects}
          />
        </Box>
      </Box>
    </Paper>
  );
};
