import { NotSearchResults } from "components/ui/NotSearchResults";
import { DepartmentsList } from "./components/DepartmentsList";
import { ErrorPage } from "pages/ErrorPage";
import { DepartmentsSkeleton } from "components/ui/DepartmentsSkeleton";
import useSWRImmutable from "swr/immutable";
import { IDepartment } from "core/types";
import { getAllDepartments } from "core/services/departmaents.service";

export const DepartmentsPage = () => {
  const {
    data: departments,
    isLoading,
    error,
  } = useSWRImmutable<IDepartment[]>("departments", getAllDepartments);

  if (isLoading) {
    return <DepartmentsSkeleton />;
  }

  if (!departments || error) {
    return (
      <ErrorPage
        error={{
          message: error?.message,
          status: error?.response?.status,
        }}
      />
    );
  }
  if (departments.length === 0) {
    return <NotSearchResults />;
  }

  return <DepartmentsList departments={departments} />;
};
