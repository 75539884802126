import axios from "axios";
import { config } from "../configs/config";
import { getTokenByTgInitData } from "./login.service";

export const api = axios.create({
  // baseURL: `${config.baseURL}/api/v1/`,
  baseURL: `https://api-corp.atomgroup.io/api/v1/`,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token !== null) {
    const data = JSON.parse(token);
    config.headers.Authorization = `Bearer ${data.token}`;
  }
  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 403) {
      window.Telegram.WebApp.close();
    } else if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const data = window.Telegram.WebApp.initData;
        await getTokenByTgInitData(data).then((res) => {
          const newToken = res.token;
          localStorage.setItem("token", JSON.stringify({ token: newToken }));
        });
        return api.request(originalRequest);
      } catch (e) {
        console.log(e, "UnauthorizedError");
      }
    }
    throw error;
  }
);
