import { Avatar } from "@mui/material";
import { stringAvatar } from "core/utils/stringAvatar";
import { IUserProps } from "core/types";
import { FC } from "react";

interface IUserPhotoProps extends IUserProps {
  size: string;
}

export const UserPhoto: FC<IUserPhotoProps> = ({ user, size }) => {
  let avatarSize = "56px";
  if (size === "large") {
    avatarSize = "180px";
  }

  return (
    <>
      {user?.photo ? (
        <Avatar
          alt={`${user?.first_name} ${user?.last_name}`}
          src={`https://admin.atomgroup.io/admin/${user?.photo}`}
          sx={{ width: avatarSize, height: avatarSize }}
        />
      ) : (
        <Avatar
          {...stringAvatar(user?.first_name, size)}
          alt={`${user?.first_name} ${user?.last_name}`}
        />
      )}
    </>
  );
};
