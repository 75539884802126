import { getTokenByTgInitData } from "core/services/login.service";
import { useState, useEffect, useCallback } from "react";
import { useTelegram } from "./useTelegram";
import { IUser } from "core/types";
import { jwtDecode } from "jwt-decode";

interface IUseAuthReturn {
  login: (token: string) => void;
  user: IUser | undefined;
  isLogin: boolean;
}

export const useAuth = (): IUseAuthReturn => {
  const { tg } = useTelegram();
  
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();

  const login = useCallback((newToken: string) => {
    localStorage.setItem("token", JSON.stringify({ token: newToken }));
    setIsLogin(true);
  }, []);

  const getToken = async () => {
    const data = tg.initData;
    try {
      const res = await getTokenByTgInitData(data);
      const token = res.token;
      login(token);
      setUser(jwtDecode(token));
    } catch (error: any) {
      console.log("Failed to get token", error);
      return;
    }
  };

  useEffect(() => {
    if (!isLogin) {
      getToken();
    }
  }, [isLogin]);

  return {
    login,
    user,
    isLogin,
  };
};
