import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { Layout } from "pages/Layout";
import { ErrorPage } from "./pages/ErrorPage";
import { HomePage } from "./pages/HomePage/HomePage";
import { DepartmentsLayout } from "pages/Departments/DepartmentsLayout";
import { UsersLayout } from "pages/Users/UsersLayout";
import { DepartmentsPage } from "pages/Departments/DepartmentsPage";
import { DepartmentPage } from "pages/Departments/DepartmentPage";
import { UsersPage } from "pages/Users/UsersPage";
import { UserPage } from "./pages/Users/UserPage";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="users/*" element={<UsersLayout />}>
          <Route index element={<UsersPage />} />
          <Route path=":userId" element={<UserPage />} />
        </Route>
        <Route path="departments/*" element={<DepartmentsLayout />}>
          <Route index element={<DepartmentsPage />} />
          <Route path=":departmentId" element={<DepartmentPage />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <ErrorPage
            error={{
              message: "Некорректная ссылка",
            }}
          />
        }
      />
    </>
  )
);
