import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import { IUserProps } from "core/types";
import { FC } from "react";
import { UserPhoto } from "components/UserPhoto";
import { useTgTheme } from "core/hooks/useTgTheme";
import { getNames } from "core/utils/getNames";
import { ListItemTextComponent } from "components/ui/ListItemTextComponent";
import { NAVIGATION_HEIGHT } from "core/configs/constants";

export const User: FC<IUserProps> = ({ user }) => {
  const { subtitle_text_color, section_bg_color } = useTgTheme();

  const departmentsNames = getNames(user, "departments");
  const subDepartmentsNames = getNames(user, "sub_departments");
  const projectNames = getNames(user, "projects");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        pt: "40px",
        pb: `${NAVIGATION_HEIGHT}px`,
      }}>
      <UserPhoto size={"large"} user={user} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography sx={{ fontSize: "20px" }} noWrap>
          {`Добро пожаловать, ${user.first_name}`}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: subtitle_text_color,
          }}
          noWrap>
          {user.email}
        </Typography>
      </Box>
      <List component="div" sx={{ width: "100%" }}>
        <ListItem>
          <ListItemTextComponent
            primary="Должность:"
            secondary={user.position.name}
          />
        </ListItem>
        <Divider sx={{ bgcolor: section_bg_color }} component="li" />
        <ListItem>
          <ListItemTextComponent
            primary="Департаменты:"
            secondary={departmentsNames}
          />
        </ListItem>
        <Divider sx={{ bgcolor: section_bg_color }} component="li" />
        <ListItem>
          <ListItemTextComponent
            primary="Субдепартаменты:"
            secondary={subDepartmentsNames}
          />
        </ListItem>
        <Divider sx={{ bgcolor: section_bg_color }} component="li" />
        <ListItem>
          <ListItemTextComponent primary="Проекты:" secondary={projectNames} />
        </ListItem>
      </List>
    </Box>
  );
};
