import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useTgTheme } from "core/hooks/useTgTheme";
import { FC } from "react";

interface IFormControlComponentProps {
  isLoading: boolean;
  label: string;
  value: number[];
  handleChange: (value: any) => void;
  options: any;
}

export const FormControlComponent: FC<IFormControlComponentProps> = ({
  isLoading,
  label,
  value,
  handleChange,
  options,
}) => {
  const { section_bg_color, text_color, button_color, subtitle_text_color } =
    useTgTheme();

  return (
    <FormControl
      disabled={isLoading}
      variant="outlined"
      size="small"
      sx={{
        flex: "1",
        "& .MuiInputLabel-root": {
          color: !isLoading ? text_color : subtitle_text_color,
        },
        "&  .MuiOutlinedInput-root": {
          backgroundColor: section_bg_color,
          color: text_color,
          "&  .MuiSvgIcon-root": {
            color: !isLoading ? text_color : subtitle_text_color,
          },
        },
      }}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        multiple
        onChange={handleChange}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: section_bg_color,
                color: text_color,
              },
            },
          },
        }}
        renderValue={(selected) =>
          selected
            .map((id) => options?.find((option: any) => option.id === id)?.name)
            .join(", ")
        }>
        {options?.map((option: any) => (
          <MenuItem key={option?.id} value={option?.id}>
            <Checkbox
              checked={value.indexOf(option?.id) > -1}
              sx={{
                color: text_color,
                "&.Mui-checked": {
                  color: button_color,
                },
              }}
            />
            <ListItemText primary={option?.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
