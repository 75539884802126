import { Box, Skeleton } from "@mui/material";

export const DepartmentsSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        py: "8px",
      }}>
      {Array.from({ length: 10 }).map((_, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            px: "16px",
          }}>
          <Skeleton
            variant="text"
            height={48}
            width={index % 2 === 0 ? 120 : 80}
          />
        </Box>
      ))}
    </Box>
  );
};
