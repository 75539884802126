import { Spinner } from "components/ui/Spinner";
import { AuthContext } from "core/context/AuthContext";
import { useContext } from "react";
import { User } from "./User";

export const HomePage = () => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Spinner />;
  }

  return <User user={user} />;
};
