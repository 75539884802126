import { FC } from "react";
import { routes } from "../../../core/configs/routes";
import { Link } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import { IUserProps } from "core/types";
import { UserPhoto } from "../../../components/UserPhoto";
import { useTgTheme } from "core/hooks/useTgTheme";
import { ListItemTextComponent } from "components/ui/ListItemTextComponent";

export const UsersListItem: FC<IUserProps> = ({ user }) => {
  const { section_bg_color } = useTgTheme();

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton component={Link} to={`${routes.USERS}/${user.id}`}>
          <ListItemAvatar
            sx={{
              mr: "20px",
            }}>
            <UserPhoto size={"small"} user={user} />
          </ListItemAvatar>
          <ListItemTextComponent
            primary={`${user.first_name} ${user.last_name}`}
            secondary={
              user.tg_username
                ? `@${user.tg_username}`
                : `ID: ${user.telegram_id}`
            }
          />
        </ListItemButton>
      </ListItem>
      <Divider
        sx={{ bgcolor: section_bg_color }}
        variant="inset"
        component="li"
      />
    </>
  );
};
