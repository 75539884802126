import useSWRImmutable from "swr/immutable";
import { UserCart } from "./components/UserCart";
import { IUser } from "core/types";
import { getUserById } from "core/services/users.service";
import { useParams } from "react-router-dom";
import { Spinner } from "components/ui/Spinner";
import { ErrorPage } from "pages/ErrorPage";
import { useBackButton } from "core/hooks/useBackButton";

export const UserPage = () => {
  const { userId } = useParams();
  useBackButton();
  const {
    data: user,
    isLoading,
    error,
  } = useSWRImmutable<IUser>(["user", Number(userId)], () =>
    getUserById(Number(userId))
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!user || error) {
    return (
      <ErrorPage
        error={{
          message: error?.message,
          status: error?.response?.status,
        }}
      />
    );
  }

  return <UserCart user={user} />;
};
