import { useBackButton } from "core/hooks/useBackButton";
import { DepartmentCart } from "./components/DepartmentCart";
import { useParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { IDepartment, IUser } from "core/types";
import {
  getDepartmentById,
  getDepartmentUsers,
} from "core/services/departmaents.service";
import { Spinner } from "components/ui/Spinner";
import { ErrorPage } from "pages/ErrorPage";

export const DepartmentPage = () => {
  const { departmentId } = useParams();
  useBackButton();

  const {
    data: department,
    isLoading: departmentsLoading,
    error: departmentError,
  } = useSWRImmutable<IDepartment>(["department", Number(departmentId)], () =>
    getDepartmentById(Number(departmentId))
  );

  const {
    data: users,
    isLoading: usersLoading,
    error: usersError,
  } = useSWRImmutable<IUser[]>(
    ["usersByDepartment", Number(departmentId)],
    () => getDepartmentUsers(Number(departmentId))
  );

  if (departmentsLoading) {
    return <Spinner />;
  }

  if (!department || departmentError) {
    return (
      <ErrorPage
        error={{
          message: departmentError?.message,
          status: departmentError?.response?.status,
        }}
      />
    );
  }

  return (
    <DepartmentCart
      department={department}
      users={users}
      usersLoading={usersLoading}
      usersError={usersError}
    />
  );
};
