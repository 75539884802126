import { Outlet } from "react-router";
import { Box } from "@mui/material";
import { Navigation } from "components/Navigation";
import { useTgTheme } from "core/hooks/useTgTheme";
import { NAVIGATION_HEIGHT } from "core/configs/constants";

export const Layout = () => {
  const { text_color, secondary_bg_color } = useTgTheme();

  return (
    <Box
      sx={{
        backgroundColor: secondary_bg_color,
        color: text_color,
      }}>
      <Box
        sx={{
          height: `calc(100vh - ${NAVIGATION_HEIGHT}px)`,
        }}
        component={"main"}>
        <Outlet />
      </Box>
      <footer>
        <Navigation />
      </footer>
    </Box>
  );
};
