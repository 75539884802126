import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTgTheme } from "core/hooks/useTgTheme";

export const NotSearchResults = () => {
  const { subtitle_text_color } = useTgTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
      }}>
      <Typography
        sx={{
          color: subtitle_text_color,
        }}>
        Нет результатов
      </Typography>
    </Box>
  );
};
